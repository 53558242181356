import StickySidebar from 'sticky-sidebar'


export default class {
		constructor() {
				this.stickyLayout = document.querySelectorAll('.js-sticky-layout');
				this.indexArea = document.querySelectorAll('.js-index__area');

				this.numbering();
				this.responsiveAction();
				this.currentSection();
				//this.accordion();
		}
		numbering() {
			//連番処理
				const stickyContents = this.stickyLayout;
				if (stickyContents) {
						stickyContents.forEach((value, index) => {
								value.classList.add('js-sticky-layout' + index);
								value.querySelector('.js-sticky-layout__anchor').classList.add('js-sticky-layout__anchor' + index);
								value.querySelector('.js-sticky-layout__content').classList.add('js-sticky-layout__content' + index);
						});
				}
		}

		responsiveAction() {
			if (this.stickyLayout) {
				window.addEventListener('load', () => {
					this.stickyAction();
				});
				//window.addEventListener('resize', () => {
				//		this.stickyAction();
				//});
			}
		}

		stickyAction() {
			var windowW = parseInt(window.innerWidth);
			if (this.stickyLayout) {
				if (windowW > 768) {
					this.following();
				}
			}
		}

		following() {
			if (this.stickyLayout) {
							for (var i = 0; i < this.stickyLayout.length; i++) {
								let stickSideAction = new StickySidebar('.js-sticky-layout__anchor' + [i], {
									containerSelector: '.js-sticky-layout' + [i],
									innerWrapperSelector: '.js-sticky-layout__content' + [i],
									//resizeSensor: true,
								});
								stickSideAction;
								//コンテンツ内にAccordionがあったとき、clickと同時に再度計算する
								const aryTrigger = Array.from(document.querySelectorAll('.js-accordion-summary'))
								if (aryTrigger) {
									for (let index = 0; index < aryTrigger.length; index++) {
										const element = aryTrigger[index];
										element.addEventListener('click', () => {
											setTimeout(() => {
												stickSideAction.updateSticky();
											}, 1000);
											setTimeout(() => {
												stickSideAction.updateSticky();
											}, 1000);
										});
									}
								}
								const AryClose = Array.from(document.querySelectorAll('.js-accordion-close'))
								if (AryClose) {
									for (let index = 0; index < AryClose.length; index++) {
										const element = AryClose[index];
										element.addEventListener('click', () => {
											setTimeout(() => {
												stickSideAction.updateSticky();
											},1000);
										});
									}
								}
								const accordions = document.querySelectorAll('.js-sticky-layout__content');
								if (accordions) {
									const anchors = document.querySelectorAll('.js-index__list a');
									for (let index = 0; index < anchors.length; index++) {
										let element = anchors[index];
										element.addEventListener('click', () => {
											setTimeout(() => {
												stickSideAction.updateSticky();
												//this.currentSection();
											}, 500);
										});
									}
								}
						}
				}
		}
		currentSection() {
			const options = {
					root: null,
					rootMargin: '-50% 0px',
					threshold: 0,
				};
				const observer = new IntersectionObserver(doWhenIntersect, options);
				this.indexArea.forEach(area => {
					observer.observe(area);
				});

				function doWhenIntersect(entries) {
					entries.forEach(entry => {
						if (entry.isIntersecting) {
							activateIndex(entry.target);
						}
					});
				}
				function activateIndex(element) {
					const currentActiveIndex = document.querySelector('.js-index__list .is-active');
						if(currentActiveIndex !== null) {
							currentActiveIndex.classList.remove('is-active');
						}
					const newActiveIndex = document.querySelector(`.js-index__list a[href='#${element.id}']`);
					newActiveIndex.classList.add('is-active');
				}
		}
}