export default class {
	constructor() {
		this.tab = '.js-tab';
		this.triggerList = '.js-tab__trigger';
		this.triggerItem = '.js-tab__trigger-item';
		this.contentsList = '.js-tab__contents';
		this.contentsItem = '.js-tab__contents-item';

		this.subTriggerList = '.js-tab__sub-trigger';
		this.subTriggerItem = '.js-tab__sub-trigger-item';

		this.init();
	  }

	  init() {
			const triggerItem = document.querySelectorAll(this.triggerItem);
			const contentsItem = document.querySelectorAll(this.contentsItem);
			const subTriggerItem = document.querySelectorAll(this.subTriggerItem);
			const aryTabs = Array.from(triggerItem);
			const aryCont = Array.from(contentsItem);
			const arySubTabs = Array.from(subTriggerItem);

			if (this.tab) {
				for (let i = 0; i < triggerItem.length; i++) {
					triggerItem[i].addEventListener('click', toggle);
				}
				function toggle() {
					for (let i = 0; i < triggerItem.length; i++) {
						triggerItem[i].classList.remove('is-active');
					}
					for (let i = 0; i < contentsItem.length; i++) {
						contentsItem[i].classList.remove('is-active');
					}
					if (subTriggerItem) {
						for (let i = 0; i < subTriggerItem.length; i++) {
							subTriggerItem[i].classList.remove('is-active');
						}
					}
					this.classList.add('is-active');

					const index = aryTabs.indexOf(this);
					aryCont[index].classList.add('is-active');
					arySubTabs[index].classList.add('is-active');
				}
			}


			if (subTriggerItem) {
				for (let i = 0; i < subTriggerItem.length; i++){
					subTriggerItem[i].addEventListener('click', subToggle);
				}

				function subToggle() {
					for (let i = 0; i < triggerItem.length; i++) {
						triggerItem[i].classList.remove('is-active');
					}
					for (let i = 0; i < contentsItem.length; i++) {
						contentsItem[i].classList.remove('is-active');
					}
					for (let i = 0; i < subTriggerItem.length; i++) {
						subTriggerItem[i].classList.remove('is-active');
					}
					this.classList.add('is-active');
					const indexSub = arySubTabs.indexOf(this);
					aryCont[indexSub].classList.add('is-active');
					aryTabs[indexSub].classList.add('is-active');
				}

			}
	  }
  }