export default class {
	constructor() {
		this.init();
	}

	init() {
		const LangBtn = document.querySelectorAll('.js-lang-button');
		const html = document.documentElement;
		window.addEventListener('load', () => {
			setTimeout(() => {
				loadActiveLang();
			}, 1000);
		});
		const loadActiveLang = () => {
			let langActive = document.querySelector('[data-stt-active]').dataset.sttChangelang;
			let langClass = '-shutto-' + langActive;
			html.classList.add(langClass);
		}
		LangBtn.forEach(element => {
			element.addEventListener('click', () => {
				let langClass = element.dataset.sttChangelang;
				langClass = '-shutto-' + langClass;
				html.classList.remove('-shutto-en','-shutto-ja');
				html.classList.add(langClass);
			});
		});
	}
}