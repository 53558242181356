export default class {
    constructor() {
        this.telInput = document.querySelector('.js-tel-input');
        this.numInput = document.querySelectorAll('.js-number');
        this.init();
        this.inputNum();
        this.zip();
    }
    //agreeCheck() {
    //    const submitBtn = document.querySelector(this.submitBtn);
    //    const checkPolicy = document.querySelector(this.policy);
    //    if(submitBtn && checkPolicy) {
    //        const path = location.pathname;
    //        if(path === "/contact/" || path === "/request/" || path === "/reservation/" || path === "/download/") {
    //            submitBtn.disabled = true;
    //            checkPolicy.checked = false;
    //        }
    //        checkPolicy.addEventListener('change', () => {
    //            // e.preventDefault();
    //            if( checkPolicy.checked === true ) {
    //                submitBtn.disabled = false;
    //            } else {
    //                submitBtn.disabled = true;
    //            }
    //        })
    //    }
    //}
    init() {
        const telInput = this.telInput;
        if (telInput) {
            telInput.setAttribute('inputmode','tel');
        }
    }
    inputNum() {
        const numInput = this.numInput;
        if (numInput) {
            numInput.forEach(element => {
                element.setAttribute('inputmode','numeric');
            });
        }
    }
    zip() {
        const zipInput = document.querySelector('[name="zip"]')
        if (zipInput) {
            zipInput.addEventListener('keydown', () => {
                AjaxZip3.zip2addr('zip', '', 'add', 'add');
            });
        }
    }
}