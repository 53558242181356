import Swiper from'swiper/bundle';
import 'swiper/css/bundle';

export default class {
    constructor() {
        this.mvNews = '.js-mv-news';
        this.newsSlider();

    }
    newsSlider() {
        const mvNews = document.querySelector(this.mvNews);
        if (mvNews) {
            const swiper = new Swiper(mvNews, {
                direction: "vertical",
                speed: 600,
                spaceBetween: 100,
                loop: true,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
                autoplay: {
                    delay: 4000,
                },
            });
        }
    }
}