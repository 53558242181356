export default class {
	constructor() {
		this.trigger = '.js-modifiedMouse-tab';
		this.triggerList = '.js-modifiedMouse-tab__list';
		this.triggerItem = '.js-modifiedMouse-tab__item';
		this.contentsList = '.js-modifiedMouse-tab__content';
		this.contentsItem = '.js-modifiedMouse-tab__content-item';

		this.init();
	}

	init() {
		const triggerItem = Array.from(document.querySelectorAll(this.triggerItem));
		const contentsItem = Array.from(document.querySelectorAll(this.contentsItem));

		if (this.trigger) {
			for (let i = 0; i < triggerItem.length; i++) {
				triggerItem[i].addEventListener('click', toggle);
			}
			function toggle() {
				for (let i = 0; i < triggerItem.length; i++) {
					triggerItem[i].classList.remove('is-active');
				}
				for (let i = 0; i < contentsItem.length; i++) {
					contentsItem[i].classList.remove('is-active');
				}
				this.classList.add('is-active');
				const index = triggerItem.indexOf(this);
				contentsItem[index].classList.add('is-active');
			}
		}
	}
}