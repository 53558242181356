import ScrollHint from 'scroll-hint';

export default class {
    constructor() {
        this.init();
    }
		init() {
			window.addEventListener('DOMContentLoaded', function(){
				new ScrollHint('.js-scrollable', {
					i18n: {
						scrollable: 'スクロールできます'
					}
				});
			});
		}
}