export default class {
    constructor() {
			this.cvBtnSafety = document.querySelector('.js-cv-btn--safety');
			this.cvBtnGenomics = document.querySelector('.js-cv-btn--genomics');
			this.cvWrap = document.querySelector('.js-cv');
			this.init();
    }
	init() {
		const cvBtnSafety = this.cvBtnSafety;
		const cvBtnGenomics = this.cvBtnGenomics;
		const cvWrap = this.cvWrap;
		if (cvWrap) {
			cvBtnSafety.addEventListener('mouseover', function () {
				cvWrap.classList.add('is-color-safety');
			});
			cvBtnSafety.addEventListener('mouseleave', function () {
				cvWrap.classList.remove('is-color-safety');
			});
			cvBtnGenomics.addEventListener('mouseover', function () {
				cvWrap.classList.add('is-color-genomics');
			});
			cvBtnGenomics.addEventListener('mouseleave', function () {
				cvWrap.classList.remove('is-color-genomics');
			});
			}
    }
}