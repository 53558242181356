export default class {
	constructor() {
		this.btn = '.js-button-history';
		this.init();
	  }

	  init() {
			const btnAry = document.querySelectorAll(this.btn);
			if (btnAry) {
				for (let index = 0; index < btnAry.length; index++) {
					let btn = btnAry[index];
					btn.addEventListener('click', () => {
						let historySecPos = document.getElementById('history').getBoundingClientRect().top;
						let scrollPos = window.scrollY;
						let posY = historySecPos + scrollPos;
						window.scrollTo({
							top: posY,
							behavior: "smooth",
						});
					})
				}
			}
	  }
  }