import {gsap} from "gsap";


export default class {
	constructor() {
		this.accordion = ".js-accordion";
		this.accordionHd = ".js-accordion.js-nav-item";
		this.summary = ".js-accordion-summary";
		this.content = ".js-accordion-content";
		this.closeBtn = ".js-accordion-close";
		this.activeClass = 'is-active';
		this.init();
		this.openAccordionTrigger();
	}
	init() {
		const accordion = document.querySelectorAll(this.accordion);
		const accordionHd = document.querySelectorAll(this.accordionHd);
		const contentAry = document.querySelectorAll(this.content);
		if(accordion.length > 0) {
			accordion.forEach((element) => {
				const summary = element.querySelector(this.summary);
				const content = element.querySelector(this.content);
				const closeBtn = element.querySelector(this.closeBtn);
				summary.addEventListener("click", (e) => {
					e.preventDefault();
					// is-activeがあるとき | アコーディオンを閉じる処理を行う
					if(element.classList.contains(this.activeClass)) {
						element.classList.remove(this.activeClass);
						this.closingAnim(content, e);
					} else {
						// is-activeがないとき | アコーディオンを開く処理を行う
							for (let index = 0; index < accordionHd.length; index++) {
								accordionHd[index].classList.remove(this.activeClass);
								this.closingAnim(accordionHd[index].querySelector(this.content), accordionHd[index]);
							}
							element.classList.add(this.activeClass);
							element.setAttribute("open", "true");
							this.openingAnim(content);
						}
					})

				//閉じるボタンがある場合
				if (closeBtn) {
					closeBtn.addEventListener("click", (e) => {
						//コンテンツ下部の閉じるを押したときに、アコーディオンのTOPの位置まで自動で戻る
						const parentWrap = closeBtn.closest('.js-accordion');
						const parentWrapPos = parentWrap.getBoundingClientRect();
						const elemtop = parentWrapPos.top + window.pageYOffset;
						document.documentElement.scrollTop = elemtop - 110;

						e.preventDefault();
						// classの切り替え
						element.classList.toggle(this.activeClass);
						// アニメーション実行
						this.closingAnim(content, element);
						// クリックしたdetailsの上の座標まで戻る
					})
				}
			})
		}
	}
	otherContAction() {
		//それ以外の要素を閉じる
		for (let index = 0; index < accordion.length; index++) {
			accordion[index].classList.remove('is-active')
			this.closingAnim(contentAry[index], accordion[index]);
		}
	}
	openAccordionTrigger() {
		const aryTrigger = Array.from(document.querySelectorAll('.js-accordion-summary'))
		if (aryTrigger) {
			const clickTriggers = document.querySelectorAll('.js-sticky-layout__anchor .c-layout01__anchor-item-link');
			if (clickTriggers) {
				for (let index = 0; index < clickTriggers.length; index++) {
					const element = clickTriggers[index];
					element.addEventListener('click', () => {
						const hrefValue = element.getAttribute('href');
						const target = document.querySelector(`#${hrefValue.slice(1)}`);
						target.querySelector('.js-accordion').classList.add('is-active');

						// open属性を付与する
						const targetIn = target.querySelector('.js-accordion');
						targetIn.setAttribute("open", "true");

						// アニメーション実行
						const accordionContent = target.querySelector('.js-accordion-content');
						this.openingAnim(accordionContent);
					})
				}
			}
		}
	}
	closingAnim(content, element) {
		gsap.to(content, {
			height: 0,
			opacity: 0,
			pointerEvents: 'none',
			//duration: 0.4,
			//ease: "power3.out",
			duration: 0.0,
			ease: 0,
			overwrite: true,
			// アニメーションの完了後、open属性を取り除く
			onComplete: () => {
				element.removeAttribute("open");
			}
		})
	}
	openingAnim(content) {
		gsap.fromTo(
			content,
			{
				height: 0,
				opacity: 0,
			},
			{
				height: "auto",
				opacity: 1,
				duration: 0.4,
				pointerEvents: 'auto',
				ease: "power3.out",
				overwrite: true,
			}
		)
	}
}