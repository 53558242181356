import "../scss/style.scss";

/**
 * @babel/polyfill の利用
 * ※ PromiseやArray.includes()など、構文ではない機能的な仕様を利用するために必要
 */
import "@babel/polyfill";

if ('NodeList' in window && !NodeList.prototype.forEach) {
    NodeList.prototype.forEach = function (callback, thisArg) {
        thisArg = thisArg || window;
        for (var i = 0; i < this.length; i++) {
            callback.call(thisArg, this[i], i, this);
        }
    };
}

import lazysizes from "lazysizes"
import unveil from "lazysizes/plugins/unveilhooks/ls.unveilhooks.min.js"

import switchViewport from "./lib/switchViewport";
import ResponsiveImages from "./lib/ResponsiveImages";
import Accordion from "./lib/Accordion"
import StickySide from "./lib/StickySide";
import Drawer from "./lib/Drawer";
import ScrollTrigger from "./lib/ScrollTrigger";
import CvContact from "./lib/CvContact";
import Fadein from "./lib/Fadein";
import SplitText from "./lib/SplitText";
import ScrollHint from "./lib/ScrollHint";
import ModifiedMouse from "./lib/ModifiedMouse";
import Home from "./lib/Home";
import Slider from "./lib/Slider";
import Modal from "./lib/Modal";
import Tab from "./lib/Tab";
import MouseStalker from "./lib/MouseStalker";
import Form from "./lib/Form";
import SelectCategory from "./lib/SelectCategory";
import About from "./lib/About";
import Language from "./lib/Language";

new switchViewport();
new ResponsiveImages();
new Accordion();
new StickySide();
new Drawer();
new ScrollTrigger();
new CvContact();
new Fadein();
new SplitText();
new ScrollHint();
new ModifiedMouse();
new Home();
new Slider();
new Modal();
new Tab();
new MouseStalker();
new Form();
new SelectCategory();
new About();
new Language();