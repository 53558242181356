import {gsap} from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export default class {
		constructor() {
			this.fadeInItems = document.querySelectorAll('.js-fadein');
			this.fadeInTxtWrap = document.querySelectorAll('.js-fadein-txt');
			this.init();
    }
	init() {
		const fadeInItems = this.fadeInItems;
		if (fadeInItems) {
			fadeInItems.forEach(element => {
				gsap.to(element, {
					scrollTrigger: {
						trigger: element,
						start: 'top 75%',
						toggleClass: {
							targets: element,
							className: 'is-active',
						}
					},
				})
			});
		}
		const fadeInTxtWrap = this.fadeInTxtWrap;
			if (fadeInTxtWrap) {
			fadeInTxtWrap.forEach(element => {
				gsap.to(element, {
					scrollTrigger: {
						trigger: element,
						start: 'top 75%',
						once: true,
						toggleClass: {
							targets: element,
							className: 'is-active',
						}
					},
				})
			});
		}
	}
}