import {gsap} from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export default class {
    constructor() {
        this.intro = document.querySelector('.js-sec-intro');
        this.mvTxt = document.querySelector('.js-mv-txt');
        this.mvCopy = document.querySelector('.js-mv-copyTxt');

        this.pathAnime = document.querySelectorAll('.js-path-animation');

        if (this.mvTxt) {
            this.txtAnimation();
            this.bgAddClassAnimation();
            this.pathAnimation();
        }
    }

    txtAnimation() {
        if (this.mvCopy) {
            window.addEventListener('load',copyActive());
            function copyActive() {
                this.mvCopy.classList.add('is-loaded');
            }
        }
        window.addEventListener('load', () => {
            this.txtFollow();
        });

        window.addEventListener('resize', () => {
            this.resizeAnimation();
            let windowW = window.innerWidth;
            if (windowW <= 1320) {
                this.mvTxt.style.height = null;
            }
        });

    }
    resizeAnimation() {
        let el = this.txtFollow;
        let timer = 0;
        window.onresize = function () {
            if (timer > 0) {
                clearTimeout(timer);
            }
            timer = setTimeout(function () {
                el;
            }, 200);
        };
    }

    txtFollow() {
        setTimeout(() => {
            this.introH = this.intro.clientHeight;
            this.mvTxt.style.height = "";
        }, 100);
        let mm = gsap.matchMedia();
        mm.add("(min-width: 1320px)", () => {
            if (this.intro) {
                this.introH = this.intro.clientHeight;
                this.mvTxt.style.height = this.introH - 110 + 'px';
            }
            gsap.set(this.mvTxt, {
                top: 0,
                scale: 1.05,
            })
            gsap.to(this.mvTxt, {
                scrollTrigger: {
                    trigger: this.mvTxt,
                    endTrigger: '.js-sec-intro',
                    end: 'bottom bottom',
                    scrub: true,
                    pin: true,
                },
                scale: 0.5,
                top: 110,
            })
        });
    }
    bgAddClassAnimation() {
        window.addEventListener('load', () => {
            this.switchBgAddClass();
        });
    }
    switchBgAddClass() {
        let windowW = window.innerWidth;
        if (windowW <= 1320) {
        } else {
            this.bgAddClass();
        }
    }
    bgAddClass() {
        ScrollTrigger.create({
            //markers: true,
            trigger: '.js-trigger-safety',
            start: 'start-=80 center',
            end: 'bottom center',
            toggleClass: {
                targets: '.p-home-intro__txt-bg',
                className: 'is-safety',
            }
        });
        ScrollTrigger.create({
            //markers: true,
            trigger: '.js-trigger-genomics',
            start: 'start-=80 center',
            toggleClass: {
                targets: '.p-home-intro__txt-bg',
                className: 'is-genomics',
            }
        });
    }
    pathAnimation() {
        if (this.pathAnime) {
            this.pathAnime.forEach(element => {
                const pathIn = element.children[0];
                const pathLine = element.children[1];
                const pathLineItem = pathLine.querySelector('path');
                const lineNum = pathLine.dataset.path;

                //set
                gsap.set(pathIn, {
                    'opacity': 0,
                    y: 10,
                });

                gsap.set(pathLineItem, {
                    'stroke-dashoffset': lineNum,
                });

                //scrollTrigger
                const pathTl = gsap.timeline({
                    scrollTrigger: {
                        trigger: element,
                        start: 'top 75%',
                        once: true,
                        toggleClass: { targets: element, className: "is-active" },
                    },
                });
                //to
                pathTl.to(pathLineItem, {
                    //ease: ,
                    ease: "circ.out",
                    'stroke-dashoffset': '0',
                    duration: 2.5,
                }).to(pathIn, {
                    opacity: 1,
                    y: 0,
                    duration: 0.8,
                },'-=0.8');
            });
        }
    }
}