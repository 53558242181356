export default class {
    constructor() {
			this.splitTxtWrap = document.querySelectorAll('.js-split-txt');
			this.init();
    }
	init() {
		const splitTxtWrap = this.splitTxtWrap;
		if (splitTxtWrap) {
			splitTxtWrap.forEach((elem)=> {
				function spanWrap() {
					const target = elem;
					const nodes = [...target.childNodes];
					let spanWrapText = ""
					nodes.forEach(node => {
						if (node.nodeType == 3) {//テキストの場合
							const text = node.textContent.replace(/\r?\n/g, '');//テキストから改行コード削除
							spanWrapText = spanWrapText + text.split('').reduce((acc, v) => {
								return acc + `<span class="js-split-txt__in">${v}</span>`
							}, "");
						} else {
							//<br>などテキスト以外の要素をそのまま連結
							spanWrapText = spanWrapText + node.outerHTML
						}
					})
					target.innerHTML = spanWrapText
					//delay　setting
					const splitTxtIn = target.querySelectorAll('.js-split-txt__in');
					splitTxtIn.forEach((el,index) => {
						var delayMs = index * 0.05;
						el.style.transitionDelay = delayMs + 's';
					});
				}
				spanWrap();
		 });
		}
	}
}