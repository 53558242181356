export default class {
	constructor() {
		this.select = document.querySelector('#selectCat');
		this.init();
	  }

	init() {
		const selectBox = this.select;
		if (selectBox) {
			selectBox.addEventListener('change', function () {
				//var url = encodeURIComponent(selectBox.value);
				var url = selectBox.value;
				location.href = url;
			});
		}
	}
}